// Modal.js
import React from "react";
import Jupiter from "./../assets/Bunnies-AI/jupiter.jpeg";
import Raydium from "./../assets/Bunnies-AI/raydium.png";
import CoinMarketCap from "./../assets/Bunnies-AI/CoinMarketCap.png";
import DexkTool from "./../assets/Bunnies-AI/dexktool.png";
import Dexscreener from "./../assets/Bunnies-AI/dexkscreen.png";
import { RxCross2 } from "react-icons/rx";

const Modal = ({ isOpen, onClose }) => {
  if (!isOpen) return null; // Modal should only render when open

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="close-button" onClick={onClose}>
          <RxCross2 color="#dcdcdc" />
        </button>

        <ul className="app_btn  banner-buy-section">
          <li className="moving_animation_leftRight">
            <a
              href="https://crane-daisy-znx8.squarespace.com/config/"
              target="_blank"
              rel="noreferrer"
            >
              <img className="blue_img" src={Jupiter} alt="App Store Blue" />
            
              <p>Buy on Jupiter</p>
            </a>
          </li>
          <li className="moving_animation_RightLeft">
            <a
              href="https://raydium.io/swap/?inputMint=sol&outputMint=AuTHEbrT36JC7NgejrDZDTtds8fLGp7MR9v6CpLBTqUF"
              target="_blank"
              rel="noreferrer"
            >
              <img className="blue_img" src={Raydium} alt="Google Play Blue" />
             
              <p>Buy on Raydium</p>
            </a>
          </li>
          <li className="moving_animation_leftRight">
            <a
              href="https://coinmarketcap.com/currencies/bunnies-ai/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="blue_img"
                src={CoinMarketCap}
                alt="Google Play Blue"
              />
            
              <p>CoinMarketCap </p>
            </a>
          </li>
          <li className="moving_animation_RightLeft">
            <a
              href="https://www.dextools.io/app/en/solana/pair-explorer/99JTuqmJRBq44L8amaPR4tyaNgPQK3D6FSKqo6Q2bZbL?t=1729246680123"
              target="_blank"
              rel="noreferrer"
            >
              <img className="blue_img" src={DexkTool} alt="Google Play Blue" />
            
              <p>Dextools</p>
            </a>
          </li>
          <li className="moving_animation_leftRight">
            <a
              href="https://dexscreener.com/solana/99jtuqmjrbq44l8amapr4tyangpqk3d6fskqo6q2bzbl"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="blue_img"
                src={Dexscreener}
                alt="Google Play Blue"
              />
             
              <p>Dexscreener</p>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Modal;
