import React, { useEffect, useState } from "react";
import Profile from "./../assets/Bunnies-AI/profile.png";
import axios from "axios";

const LevelBronze = ({ bronzeName, bronzeValue, levelNumber, gradient }) => {
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  useEffect(() => {
    setLoading(true);
    (async () => {
      const resp = await axios.post(
        "https://api.bunniesai.com/v1/users/getUsersByLevel",
        {
          level: levelNumber,
        }
      );
      setUsers(resp.data);
      console.log(resp.data, "========================>", gradient);
      setLoading(false);
    })();
    // }
  }, []);
  const formatToK = (value) => {
    if (typeof value !== "number" || !isFinite(value)) {
      return "Invalid value";
    }
    if (value >= 1000 && value < 1000000) {
      return (value / 1000).toFixed(1) + "K";
    } else if (value >= 1000000) {
      return (value / 1000000).toFixed(1) + "M";
    }
    return value.toFixed();
  };

  return (
    <div
      className="level_bronze"
      style={{
        background: gradient,
        backgroundSize: "300%",
        backgroundPosition: "center",
      }}
    >
      <div
        className={`level_bronze_img-container ${
          users.length === 0 ? "level_bronze_img-conditional" : ""
        }`}
        style={{
          background: gradient,
          backgroundPosition: "center",
          backgroundSize: "140%",
        }}
      >
        <div className="level_bronze_img">
          <img src={Profile} alt="Profile" />
        </div>
        <div className="level-info">
          <h3>{bronzeName}</h3>
          <p>{bronzeValue}</p>
        </div>
      </div>

      <div
        className="level_bronze_line-container"
        style={{ background: gradient }}
      >
        {users.map((user, index) => (
          <div className="level_bronze_line" key={index}>
            <div className="left">
              <div className="line-image">
                <img src={user?.profileImage || Profile} alt="" />
              </div>
              <div className="line-info">
                <h3>{user.name}</h3>
                <p>{formatToK(user.tokens)}</p>
              </div>
            </div>
            <div className="right">
              <h2>{index + 1}</h2>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LevelBronze;
